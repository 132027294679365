* {
  box-sizing: border-box;
}

.topAction {
  position: absolute;
  top: 20px;
  left: 20px;
}

#virKeyboard {
  display: none;
}

.bottomAction {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}

input {
  border-radius: 10px;
  border: 1px solid white;
  font-size: 20px;
  padding: 15px;
}

#inputSearch {
  font-family: IBM Plex Sans Thai;
  width: 100%;
  display: block;
  max-width: 450px;
  margin: 0 auto;
  padding: 15px 30px;
}

#searchResult {
  display: none;
  background: white;
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 450px;
}

.shopIcon {
  width: 30px;
}

.searchItem {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 0;
  font-size: 18px;
}

.searchItem:nth-child(odd) {
  border-bottom: 1px solid rgb(228, 228, 228);
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-space {
  min-width: 150px;
  max-width: none;
}

.simple-keyboard.hg-theme-default .hg-button[data-skbtnuid^="numbers-"] {
  width: 33%;
  max-width: none;
}

.simple-keyboard .hg-button-numbers {
  max-width: 80px;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-HIDE {
  max-width: 80px;
}

.ibm-plex-sans-thai-thin {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-thai-extralight {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-thai-light {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-thai-regular {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-thai-medium {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-thai-semibold {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-thai-bold {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 700;
  font-style: normal;
}
